const ITEMS = require('./items-processed.json');

export type Recipe = {
  name: String,
  isBase?: boolean,
  input: Input,
  makes?: number
};

export type Input = {
  [index: string]: number
};

export type RecipeList = {
  [index:string]: Recipe
};

export const Recipes: RecipeList = {
  Sulphur: {
    name: "Sulphur",
    isBase: true,
    input: {},
    makes: 1
  },
  Charcoal: {
    name: "Charcoal",
    isBase: true,
    input: {}
  },
  Gunpowder: {
    name: "Gunpowder",
    input: {
      Sulphur: 2,
      Charcoal: 3
    },
    makes: 10
  },
  ExplosiveAmmo: {
    name: "Explosive Ammo",
    input: {
      Gunpowder: 10,
      Sulphur: 5,
      MetalFragment: 5
    },
    makes: 2
  },
  MetalFragment: {
    name: "Metal Fragments",
    isBase: true,
    input: {}
  },
  ...ITEMS.reduce((mapped: RecipeList, item: Recipe) => {
    mapped[item.name.replace(/[ \-_]/ig, '')] = Object.assign({
      ...item,
      // name: item.name.replace(/[ \-_]/ig, ''),
      input: item.input ? Object.keys(item.input).reduce((newInput: Input, itemKey: string) => {
        newInput[itemKey.replace(/[ \-_]/ig, '')] = item.input[itemKey] || 1;
        return newInput;
      }, {}) : {}
    });
    return mapped;
  }, {})
};

console.log(Recipes)
export default Recipes;
