import Recipes, { Recipe } from '../data/items';

export type ReducedRecipe = {
  stages: Array<Object>,
  items: {
    [index: string]: number
  }
}

const initialState = () => ({
  stages: [],
  items: {}
})

const reduceIngredients = (accumulator: ReducedRecipe = initialState(), recipe: Recipe, quantity = 1): ReducedRecipe => {
  return Object.keys(recipe.input)
    .reduce((sum: ReducedRecipe, ingredientName: string): ReducedRecipe => {
      const itemRecipe = Recipes[ingredientName.replace(/[ \-\_]/ig, "")];
      if (!itemRecipe) {
        console.error(`Could not find ${ingredientName}`);
        return accumulator;
      }
      const ingredientQuantity = recipe.input[ingredientName] * quantity;

      // Add ingredients to totals
      sum.items[ingredientName] = sum.items[ingredientName] || 0;
      sum.items[ingredientName] += ingredientQuantity;

      // Add crafted ingredients as a stage of crafting
      if (itemRecipe.input && !itemRecipe.isBase) {
        sum.stages.push({ [ingredientName]: ingredientQuantity });
      }

      // Stop reducing the recipe if this item has no parts,
      // or if this item is a "base" material (e.g. Metal frags)
      if (
        !itemRecipe.input ||
        !Object.keys(itemRecipe.input) ||
        itemRecipe.isBase
      ) {
        return sum;
      }

      // Continue reducing ingredients of this ingredient ... etc. (multiplied by quantity required)
      return reduceIngredients(sum, itemRecipe, ingredientQuantity);
    }, accumulator);
};

export default reduceIngredients;