import React, { useState } from 'react';
import styled from 'styled-components';
import 'bulma/css/bulma.css';

import { Recipes as RECIPES, Recipe, RecipeList } from './data/items';
import reduceIngredients from './recipes/reduceIngredients';

import CraftingDisplay from './calculator/components/CraftingDisplay';

const Container = (props: {children?: any}) => <div className='container'>{props.children}</div>;
const Section = (props: {children?: any}) => <section className='section'>{props.children}</section>;
const Content = (props: {children?: any}) => <div className='content'>{props.children}</div>;
const Field = ({ isGrouped, children, ...props }: { children?: any, isGrouped: boolean }) => <div className={`field ${isGrouped && 'is-grouped'}`}>{children}</div>
const FieldGroup = (props: { children?: any}) => <Field isGrouped>{props.children}</Field>;

const RecipeSelect = (props: {onChange: any, value: string, items: RecipeList}) => {
  return (
    <div className="select">
      <select onChange={props.onChange} value={props.value}>
        {
          Object.keys(props.items)
            .filter(key => !props.items[key].isBase)
            .map((key) => {
              return <option key={`item-${key}`} value={key}>{props.items[key].name}</option>;
            })
        }
      </select>
    </div>
  )
}

function App () {
  const [selectedRecipe, updateSelectedRecipe] = useState('ExplosiveAmmo');
  const [quantity, updateQuantity] = useState(64);

  const onQuantityChange = (e: React.ChangeEvent<HTMLInputElement>) => updateQuantity(+e.target.value);
  const onRecipeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    updateSelectedRecipe(e.target.value);
    updateQuantity(1);
  }

  const reduced = reduceIngredients(undefined, RECIPES[selectedRecipe], quantity);

  return (
    <Container>
      <Section>
        <FieldGroup>
          <RecipeSelect value={selectedRecipe} items={RECIPES} onChange={onRecipeChange} />
          <input className="input" value={quantity} onChange={onQuantityChange} />
        </FieldGroup>
        
        <Content>
          <CraftingDisplay recipe={RECIPES[selectedRecipe]} quantity={quantity} reducedRecipe={reduced} />
        </Content>
        
      </Section>
      
    </Container>
  );
}

export default App;
