import React from 'react';
import styled from 'styled-components';

import Recipes, { Recipe } from '../../data/items';
import { ReducedRecipe } from '../../recipes/reduceIngredients';

interface Props {
  recipe: Recipe,
  quantity: number,
  reducedRecipe: ReducedRecipe
}

const NestedList = styled.ul`
  margin-top: 0 !important; /* oh no, here we go */
`;

function CraftingDisplay({ recipe, quantity, reducedRecipe }: Props) {
  return (
    <div>
      <ol>
        <li>
          Collect:
          <NestedList>
            {Object.keys(reducedRecipe.items)
              .filter(item => item !== "stages")
              .filter(item => Recipes[item].isBase)
              .map((item, i) => {
                const quantity = reducedRecipe.items[item];
                return (
                  <li key={`collect-list-${i}`}>
                    {quantity} {item}
                  </li>
                );
              })}
          </NestedList>
        </li>
        {reducedRecipe.stages.reverse().map(stage => {
          const [[name, quantity]] = Object.entries(stage);
          const makes: number = (Recipes[name].makes || 1);
          return (
            <li key={`stages-list-${name}`}>
              Craft {quantity} {name}{" "}
              <em>({quantity / makes} crafts)</em>
            </li>
          );
        })}
        <li>
          Craft your {recipe.name} <em>({(quantity / (recipe.makes || 1))} crafts)</em>
        </li>
      </ol>
    </div>
  )
}

export default CraftingDisplay;